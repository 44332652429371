<!-- * CREAR ANUNCIO -->
<div class="modal" *ngIf="!data">
	<div class="header">
		<span></span>
		<button class="btn btn-close" mat-dialog-close="true">
			<mat-icon svgIcon="mat_outline:close"></mat-icon>
		</button>
	</div>

	<div class="modal-body">
		<img src="/assets/images/modal-success.svg" alt="success-logo" />

		<h2 class="font-600">{{ 'modal.createAd' | translate }}</h2>

		<button class="btn-primary btn-block" mat-dialog-close="true">
			{{ 'modal.backToMyAds' | translate }}
		</button>
	</div>
</div>

<!-- * CREAR CUENTA PROFESIONAL -->
<div class="modal" *ngIf="data?.type === 1">
	<div class="header">
		<span></span>
		<button class="btn btn-close" mat-dialog-close="true">
			<mat-icon svgIcon="mat_outline:close"></mat-icon>
		</button>
	</div>

	<div class="modal-body">
		<img src="/assets/images/modal-success.svg" alt="success-logo" />

		<h1 class="title font-600">{{ 'modal.welcomeTo' | translate }}</h1>

		<h2>
			<span *ngIf="data?.code">
				<span class="font-600">{{ 'modal.code.part1' | translate }} </span>
				<span class="font-600">{{ data?.code }}</span
				>.
			</span>
			<span> {{ 'modal.code.part2' | translate }}</span>
		</h2>
		<button class="btn-primary btn-block" mat-dialog-close="true">
			{{ 'globals.continue' | translate }}
		</button>
	</div>
</div>

<!-- * ELIMINAR ANUNCIO -->
<div class="modal" *ngIf="data?.type === 2">
	<div class="header">
		<span></span>
		<button class="btn btn-close" [mat-dialog-close]="false">
			<mat-icon svgIcon="mat_outline:close"></mat-icon>
		</button>
	</div>

	<div class="modal-body">
		<img src="https://cdn.autosmotos.es/assets/Icon-trash.webp" alt="trash-logo" />

		<h2 class="font-600">{{ 'modal.deleteAd.title' | translate }}</h2>
		<h3 class="font-600">{{ 'modal.deleteAd.subTitle' | translate }}</h3>

		<button class="btn-primary btn-block" [mat-dialog-close]="true">
			{{ 'modal.deleteAd.confirm' | translate }}
		</button>
		<button class="btn-primary-inv btn-block" [mat-dialog-close]="false">
			{{ 'modal.deleteAd.cancel' | translate }}
		</button>
	</div>
</div>

<!-- * FAVORITO NO LOGUEADO -->
<div class="modal" *ngIf="data?.type === 3">
	<div class="header">
		<span></span>
		<button class="btn btn-close" mat-dialog-close="true">
			<mat-icon svgIcon="mat_outline:close"></mat-icon>
		</button>
	</div>

	<div class="modal-body">
		<div class="circle-back">
			<mat-icon svgIcon="mat_outline:favorite_border"></mat-icon>
		</div>

		<h2 class="font-bold">{{ 'modal.favoriteAlert' | translate }}</h2>
	</div>
</div>

<!-- * EDIT ANUNCIO -->
<div class="modal" *ngIf="data?.type === 4">
	<div class="header">
		<span></span>
		<button class="btn btn-close" mat-dialog-close="true">
			<mat-icon svgIcon="mat_outline:close"></mat-icon>
		</button>
	</div>

	<div class="modal-body">
		<img src="/assets/images/modal-success.svg" alt="success-logo" />

		<h2 class="font-600">{{ 'modal.editAd' | translate }}</h2>

		<button class="btn-primary btn-block" mat-dialog-close="true">
			{{ 'modal.backToMyAds' | translate }}
		</button>
	</div>
</div>

<!-- * NOT CONFIRM DATA -->
<mat-dialog-actions class="modal" *ngIf="data?.type === 5">
	<div class="header">
		<span></span>
		<button class="btn btn-close" mat-dialog-close="true">
			<mat-icon svgIcon="mat_outline:close"></mat-icon>
		</button>
	</div>

	<div class="modal-body">
		<div class="circle-content">
			<mat-icon svgIcon="mat_outline:schedule"></mat-icon>
		</div>
		<h1 class="font-600">{{ 'modal.notApproveAlert.title' | translate }}</h1>
		<h4>{{ 'modal.notApproveAlert.subTitle' | translate }}</h4>
	</div>
</mat-dialog-actions>

<!-- * EMAIL NOT CONFIRM -->
<mat-dialog-actions class="modal" *ngIf="data?.type === 6">
	<div class="header">
		<span></span>
		<button class="btn btn-close" mat-dialog-close="true">
			<mat-icon svgIcon="mat_outline:close"></mat-icon>
		</button>
	</div>

	<div class="modal-body">
		<div class="circle-content">
			<mat-icon svgIcon="mat_outline:mail"></mat-icon>
		</div>
		<h1 class="font-bold">{{ 'modal.notEmailConfirm.title' | translate }}</h1>
		<h4>{{ 'modal.notEmailConfirm.subTitle' | translate }}</h4>
		<h4 *ngIf="!isDisabled">{{ 'modal.notEmailConfirm.resendEmail' | translate }}</h4>
		<h4 *ngIf="isDisabled">{{ 'modal.notEmailConfirm.emailSended' | translate }}</h4>
		<button
			mat-raised-button
			color="primary"
			class="!w-full"
			*ngIf="!isDisabled"
			[disabled]="isDisabled"
			(click)="sendEmail()"
		>
			<span *ngIf="!isLoading">{{ 'modal.notEmailConfirm.sendEmail' | translate }}</span>
			<mat-progress-spinner
				diameter="20"
				mode="indeterminate"
				color="accent"
				*ngIf="isLoading"
			></mat-progress-spinner>
		</button>
	</div>
</mat-dialog-actions>

<!-- * FREE AD OCASIONAL -->
<mat-dialog-actions class="modal" *ngIf="data?.type === 7">
	<div class="header">
		<span></span>
		<button class="btn btn-close" mat-dialog-close="true">
			<mat-icon svgIcon="mat_outline:close"></mat-icon>
		</button>
	</div>

	<div class="modal-body">
		<img
			width="250px"
			src="https://cdn.autosmotos.es/assets/search-not-found.webp"
			alt="not-found-logo"
		/>

		<h2 class="font-600">{{ 'modal.freeAd' | translate }}</h2>
	</div>
</mat-dialog-actions>
