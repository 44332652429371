import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthServicePages } from '@core/auth/service/auth-other.service';
import { TranslateModule } from '@ngx-translate/core';
import { LangService } from '@shared/services/lang.service';
import { finalize } from 'rxjs';

@Component({
	selector: 'app-modals-alert',
	templateUrl: './modals-alert.component.html',
	styleUrls: ['./modals-alert.component.scss'],
	standalone: true,
	imports: [
		TranslateModule,
		MatIconModule,
		NgIf,
		MatDialogModule,
		MatProgressSpinnerModule,
		MatButtonModule
	]
})
export class ModalsAlertComponent {
	isDisabled: boolean = false;
	isLoading: boolean = false;

	constructor(
		public dialogRef: MatDialogRef<ModalsAlertComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private _authService: AuthServicePages,
		private _langService: LangService
	) {}

	sendEmail(): void {
		this.isLoading = true;

		const currentLang = this._langService.currentLang.value;
		const currentDomain = this._langService.currentDomain.value;

		this._authService
			.sendConfirmationEmail(currentLang, currentDomain)
			.pipe(
				finalize(() => {
					this.isLoading = false;
				})
			)
			.subscribe((resp) => {
				if (resp.ok) {
					this.isDisabled = true;
				}
			});
	}
}
